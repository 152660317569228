import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import TopicCard from '../../components/ui/Cards/TopicCard/TopicCard';
import MoreArticles from '../../components/ui/Home/MoreArticles/MoreArticles';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import { PostsSelector } from '../../components/utils/PostsSelector';
import Seo from '../../components/utils/Seo';
import { TopicsDetailsSelector } from '../../components/utils/TopicDetailsSelector';
import { useAnimationOnScroll } from '../../components/utils/useAnimateOnScroll';
import './learn-ai.scss';

interface LearnAiProps {
    data: {
        learnAiYaml: {
            image: FluidImage;
            postsPerTopic: PostsPerTopicBlock[];
        };
        topicsYaml: {
            topics: TopicDetails[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
    };
}

const renderGroup = (
    groupIndex: number,
    topics: PostsPerTopicBlock[],
    posts: Record<string, Frontmatter>,
    topicsDetails: Record<string, TopicDetails>,
    groupSize: number
) => {
    const renderedItems = [];

    for (let j = 0; j < groupSize; j++) {
        const t = topics[groupIndex * groupSize + j];
        renderedItems.push(
            <TopicCard
                topic={topicsDetails[t.topic]}
                key={t.topic}
                posts={t.posts.map((p) => posts[p])}
            />
        );
    }
    return renderedItems;
};

const LearnAi: React.FC<LearnAiProps> = ({ data }: LearnAiProps) => {
    const { posts, postsPerTopic } = new PostsSelector(
        data.allMdx.nodes
    ).getByTopic(data.learnAiYaml.postsPerTopic);
    const topicsDetails = new TopicsDetailsSelector(
        data.topicsYaml.topics
    ).get();

    const groupSize = 3;
    const groupsNum = Math.floor(
        data.learnAiYaml.postsPerTopic.length / groupSize
    );
    useAnimationOnScroll();

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Learn AI"
                url="learn-ai/"
                description="All the different topics covered on AI Summer"
            />

            <PageBanner className="page-banner-withcircularimage">
                <Fragment>
                    <h1>Deep Learning topics</h1>
                    <h3>
                        Over the past year, we have explored 21 different Deep
                        Learning topics and have written almost 100 high detailed
                        articles and tutorials
                    </h3>
                </Fragment>
                <Fragment>
                    <Img
                        fluid={data.learnAiYaml.image.childImageSharp.fluid}
                        alt="Learn AI"
                    ></Img>
                </Fragment>
            </PageBanner>
            <PageSection className="learn-ai-section">
                <Container>
                    <h2 > Explore our latest articles</h2>
                    <MoreArticles
                        posts={data.allMdx.nodes.map(
                            (node) => node.frontmatter
                        )}
                    />
                </Container>
            </PageSection>
            <PageSection className="learn-ai-section">
                <Container>
                    <h2>Explore our topics</h2>
                    {[...Array(groupsNum).keys()].map((groupIndex) => (
                        <Group key={groupIndex} size={groupSize}>
                            {renderGroup(
                                groupIndex,
                                postsPerTopic,
                                posts,
                                topicsDetails,
                                groupSize
                            )}
                        </Group>
                    ))}
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const learnAiQuery = graphql`
    {
        learnAiYaml {
            image {
                childImageSharp {
                    fluid {
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }
            postsPerTopic {
                topic
                posts
            }
        }
        topicsYaml {
            topics {
                desc
                title
                url
                logo
            }
        }
        allMdx(
            filter: { frontmatter: { layout: { eq: "BlogPage" } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                base64
                                aspectRatio
                                src
                                srcSet
                                sizes
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
    }
`;

export default LearnAi;
